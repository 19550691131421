const data = [
  { name: "Home", route: "/home" },
  { name: "Equipe", route: "/equipe" },
  /*
  { 
    name: "Casos",
    children: [
      { name: "RECUPERAÇÕES JUDICIAIS", route: "/casos/recuperacoes"},
      { name: "FALÊNCIAS", route: "/casos/falencias"},
      { name: "LIQUIDAÇÃO JUDICIAL", route: "/casos/liquidacao-judicial"},
      { name: "INSOLVÊNCIA CIVIL", route: "/casos/insolvencia-civil"},     
    ],
  },
  */
  { name: "Na Midia", route: "/na-midia" },
  { name: "Dúvidas", route: "/duvidas" },
  { name: "Contato", route: "/contato" },
 // { name: "Area Credor", route: "/area-credor" },
  
];

export default [...data];
