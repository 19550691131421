const data = [
    //{ name: "ENVIO DE HABILITAÇÕES E DIVERGÊNCIAS", route: "/envio-habilitacoes-divergencias" },
    { name: "Resumo Geral", route: "/resumo-geral" },
    { name: "HABILITAÇÕES / DIVERGÊNCIAS", route: "/habilitacoes-divergencias" },
    { name: "MODELOS DE DOCUMENTOS", route: "/modelos-documentos" },
    { name: "ASSEMBLEIA DE CREDORES", route: "/assembleia-credores" },
    { name: "CENTRAL DE DOCUMENTOS", route: "/central-documentos" },
    // { name: "CREDENCIAMENTO", route: "/credenciamento" },
];

export default [...data];
