<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-6 title-page">Cadastro</div>
                    <p>
                        Preencha os campos abaixo para realizar o cadastro.
                    </p>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="validForm">
                <v-row>
                    <v-col>
                        <v-text-field dense id="name" label="Nome completo" v-model="dataset.data.name" required
                            :rules="[$rules.required]" outlined hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense id="registration" label="CPF/CNPJ" v-model="dataset.data.registration" required
                            v-mask="dataset.data.registration && dataset.data.registration.length < 15 ? this.$masks.cpf : this.$masks.cnpj"
                            :rules="[$rules.required, (dataset.data.registration && dataset.data.registration.length < 15 ? $rules.cpf : $rules.cnpj)]"
                            outlined hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense label="Celular" v-model="dataset.data.phone_number" required
                            :rules="[$rules.required, $rules.phone]" v-mask="this.$masks.phone" outlined
                            hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense id="email" label="E-mail" v-model="dataset.data.email" required
                            :rules="[$rules.required, $rules.email]" outlined hide-details="auto">
                        </v-text-field>
                        <!-- <span class="important-text">
                            * De preferência, crie este cadastro com o mesmo e-mail utilizado no site da 123
                            Milhas/Hotmilhas,
                            em
                            que recebeu o comprovante de pagamento da sua compra e/ou venda
                        </span> -->
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined label="Senha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            required :rules="[$rules.required, $rules.strongPassword]"
                            :type="showPassword ? 'text' : 'password'" v-model="dataset.data.password"
                            @click:append="showPassword = !showPassword" hide-details="auto" id="password"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined label="Repita a senha"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" required :rules="[
                                $rules.required, $rules.strongPassword,
                                dataset.data.password === dataset.data.passwordConfirmation || 'Senha não confere']"
                            :type="showPassword ? 'text' : 'password'" v-model="dataset.data.passwordConfirmation"
                            @click:append="showPassword = !showPassword" hide-details="auto" id="password"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined counter
                            required :rules="[$rules.required, $rules.fileSize(4)]"
                            hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                            label="Documento de identificação (CPF, RG, CNH ou Contrato Social)" :clearable="true"
                            @change="onFileSelected($event)" ref="logoFile">
                        </v-file-input>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-4">
                    <v-col>
                        <v-checkbox :rules="[$rules.required]" v-model="dataset.data.terms">
                            <div slot='label'>
                                <span>Eu li e concordo com os <a class="checkbox-link">Termos de Uso</a> e a <a
                                        class="checkbox-link">Política de Privacidade</a></span>
                            </div>
                        </v-checkbox>
                        <span class="important-text">
                            * Ao aceitar você estará ciente das práticas de coleta, uso, e divulgação de informações
                            pessoais descritas nas
                            políticas mencionadas, e autorizo o processamento dos meus dados de acordo com tais termos.
                        </span>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-text>
            <v-btn color="primary" @click.native="create" :loading="loading">Finalizar</v-btn>
            <v-btn color="grey" text @click.native="goBack">Cancelar</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main";

import axios from "axios"

import string from "@/common/util/string"

export default {
    data: () => ({
        showPassword: false,
        validForm: false,
        loading: false,
        dataset: {
            data: {
                password: "",
                passwordConfirmation: ""
            }
        }
    }),

    mounted: function () {
    },

    created() {
    },

    computed: {
        // getMask() {
        //     return
        // }
    },

    methods: {
        onFileSelected(file) {
            this.dataset.data.file = file;
        },

        async create() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja finalizar o cadastro?',
                title: 'Cadastro',
                actions: {
                    false: 'Cancelar',
                    true: 'Finalizar'
                }
            })

            if (!proceedAction) return;

            this.loading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/users', payload);

                await this.$dialog.confirm({
                    text: 'Cadastro realizado com sucesso! Um e-mail foi enviado para a sua caixa de entrada, acesse-o para verificar sua conta.',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                })

                this.goBack()
            } catch (error) {
                if (error && error.response && error.response.data.content) {
                    const errorTmp = error.response.data.content.error;
                    if (errorTmp.code == 1032) {
                        this.$dialog.confirm({
                            text: 'Seu CPF/CPNJ foi vinculado a mais de um nome na lista de credores. Seu pedido de habilitação/divergência foi registrado, mas deverá ser complementado. A Administração Judicial entrará em contato por e-mail com instruções sobre como prosseguir.',
                            title: 'Atenção',
                            actions: {
                                true: 'OK'
                            }
                        })
                    }
                }
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.loading = false;
            }

        },

        goBack() {
            this.dataset.data = {};
            eventHub.$emit('DIALOG-REGISTER', false)
        },

        buildPayload() {
            const formData = new FormData()

            formData.append('name', this.dataset.data.name);
            formData.append('registration', string.sanitizeCpfCnpj(this.dataset.data.registration));
            formData.append('email', this.dataset.data.email);
            formData.append('phone_number', string.sanitizePhone(this.dataset.data.phone_number));
            formData.append('password', this.dataset.data.password);
            formData.append('terms', this.dataset.data.terms);

            formData.append('file', this.dataset.data.file)

            return formData;
        }
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.checkbox-link {
    text-decoration: underline;
    font-weight: bold;
}

p {
    text-align: justify;
}
</style>
